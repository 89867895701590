





















































export default {
  name: 'MDeleteButton',
  props: {
    iconDelete: {
      type: String,
      default: 'mdi-trash-can-outline',
    },
    textModal: {
      type: String,
      required: false,
      default: null,
    },
    classes: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      openmodal: false,
    }
  },
}
