

















































































































import { defineComponent, ref } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { Vehicle } from '@/api/interfaces/vehicle'
import { QueryParams } from '@/store/api-plateform-utils'
import {
  usePagination,
  PaginationOptions,
  parseQuery,
} from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import router from '@/router'
import deleteButton from '@/components/molecules/m-delete-button.vue'
import { userConnectedHasRole } from '@/composables/UserGrant'

export default defineComponent({
  setup (props, ctx) {
    const canDeleteVehicle = () => userConnectedHasRole('ROLE_DELETE_VEHICULE')
    const query = {
      ...router.app.$route.query,
    }
    const filters = ref({
      reference: query.reference || '',
      area: query.area || '',
    })
    delete query.reference
    const totalItems = () => {
      return ctx.root.$store.state.vehicleList.totalItems
    }
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      filters,
      canDeleteVehicle,
    }
  },
  name: 'VehicleList',
  components: {
    deleteButton,
    ListTextFilter: () => import('@/components/base/ListTextFilter.vue'),
    ListSelectFilter: () => import('@/components/base/ListSelectFilter.vue'),
    Pagination,
  },
  data () {
    return {
      title: this.$t('vehicle.list.title'),
      headers: [
        {
          text: this.$t('vehicle.list.headers.created_at'),
          align: 'start',
          value: 'createdAt',
        },
        {
          text: this.$t('vehicle.list.headers.updated_at'),
          value: 'renewAt',
        },
        {
          text: this.$t('vehicle.list.headers.model'),
          value: 'carModel.model',
        },
        {
          text: this.$t('vehicle.list.headers.brand'),
          value: 'carBrand.brand',
          sortable: false,
        },
        {
          text: this.$t('vehicle.list.headers.reference'),
          value: 'reference',
        },
        {
          text: this.$t('vehicle.list.headers.matriculation'),
          value: 'matriculation',
          sortable: false,
        },
        {
          text: this.$t('vehicle.list.headers.area'),
          value: 'area.name',
          sortable: false,
        },
        {
          text: this.$t('vehicle.list.headers.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('vehicleList', {
      vehicleList: 'list',
      loading: 'loading',
    }),
    ...mapState('area', {
      listAreas: 'list',
    }),
    ...mapState('carModeleList', {
      carModelList: 'list',
    }),
    ...mapState('filterList', ['filter']),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        ...this.filters,
        ...this.paginationRequestParams,
      }

      return request as QueryParams
    },
  },
  created () {
    this.load(this.baseRequest)
  },
  watch: {
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.loadData()
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('vehicleList', {
      load: 'load',
      deleteById: 'deleteById',
    }),
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.filters,
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )
      router.replace({ name: 'Vehicle List', query: query })
    },
    gotToVehicleForm (item: Vehicle) {
      if (item.id) {
        this.$router.push({
          name: 'Vehicle Edit',
          params: { idVehicle: item.id.toString() },
        })
      }
    },
    filterByReference (value: string) {
      this.filters.reference = value
    },
    filterByArea (value: string) {
      this.filters.area = value
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Vehicle Edit' || to.name === 'Vehicle Create') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
